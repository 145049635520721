import React from 'react'
import "./Footer.css"
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <section>
                <div className="container-fluid " style={{ backgroundColor: "#000C14" }}>
                    <div className="container p-lg-5 ">
                        <div className="row text-center pt-2">
                            <div className='LogoSectionFooter'>
                                <a className="text-decoration-none" href="/">
                                    <h3>
                                        <span>I</span>
                                        cloudsoft
                                    </h3>
                                </a>
                                <h6>
                                    Online Success Starts Here!
                                </h6>

                                <div className='SocialIconFooter'>
                                    <NavLink to="https://www.facebook.com/share/1HLCSgUj7B/" target="_blank"><i className="fa-brands fa-facebook-f "></i></NavLink>
                                    <NavLink to="https://twitter.com/icloudsoftTech " target="_blank"><i className="fa-brands fa-twitter"></i></NavLink>
                                    <NavLink to="https://www.instagram.com/icloud_soft/" target="_blank"><i className="fa-brands fa-instagram" ></i></NavLink>
                                    <NavLink to="https://www.linkedin.com/company/icloudsoft/" target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                                </div>

                                <h5>
                                    Office No 701, 7th Floor, Lotus Building, Opp Joshi Railway Museum
                                </h5>

                                <h4>
                                    Kothrud , Pune 411038
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid d-flex  justify-content-center align-items-center " >
                        <div className='text-center text-white FooterBotomm' >
                            <p>
                                <span>
                                    2024 Icloudsoft. All Rights Reserved
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer